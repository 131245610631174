
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.bg-grey {
    background: #F9FBFC;
}

.colorGrey {
    color: #686868;
}

.borderRound {
    border: 1px solid #EBECEC;
}
ul.sideMenu li {
    transition: .2s;
}
ul.sideMenu li a {
    color: #707478;
    font-size: 14px;
    line-height: 21px;
}
ul.sideMenu li:hover a {
    color:#000;
    font-weight: 500;
}
ul.sideMenu li:hover {
    background: #EEF2F4;
    border-radius: 10px;
}

ul.sideMenu li.active {
    background: #EEF2F4;
    border-radius: 10px;
}

ul.sideMenu li.active a {
    color:#000;
    font-weight: 500;
}

ul.sideMenu li.active svg {
    fill: #000;
}

.sideBar {
    border-right: 1px solid #EBECEC;
    width:250px;
}

ul.sideMenu svg {
    fill: #707478;
}

ul.sideMenu li:hover svg {
    fill: #000;
}

.sidebar-Right {
    border-left: 1px solid #EBECEC
}

.centerContent {
    background-color: #fff;
}

.centerContent span:first-child {
    width: 30px;
    height: 30px;
}

.centerContent span:first-child img {
    width: 100%;
}

.centerContent div > div {
    border-right: 1px solid #EBECEC;
}

.centerContent div > div:last-child {
    border-right: none;
}

.centerContent span:last-child {
    color: #686868;
}

.centerContent span:nth-child(2) {
    color: #3F3F3F;
}

.input-group input {
    color: #686868;
}

.input-group input::placeholder {
    color: #686868;
}

.counter {
    background: #F2F2F2;
    color: #686868;
}
.card {
    border-bottom: 1px solid #EBECEC;


}

/* 
.card-wrapper .card:last-child {
    border-bottom: none;
} */

.card-wrapper.grid .card:last-child {
    border-bottom: 1px solid #EBECEC;
}

.card p {
    color: #686868;
}

.card p span {
    color: #535353;
}

.timer {
    background: #E3FADA;
    color: #59CA31;
}

.sidebar-Right h3 {
    color: #3F3F3F;
}

.sidebar-Right p {
    color: #9B9B9B;
}

.UsersText p span {
    color: #686868;
}

.card-wrapper .card.borderRound.greenBorder {
    border: 2px solid #59CA31;
}

.borderRound.relative .timer {
    border-radius: 0px 3px;
}
/* 
#gridView {
    display: none;
} */

.btn.active {
    background: #EEF2F4;
}

 .card-wrapper .card {
    animation: animate 2s linear;
}

.AnimateCard {
    animation: animate 2s linear;
}

@keyframes animate {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 64, 0.7), 0 0 0 0 rgba(255, 0, 64, 0.7);
    }
    40% {
        box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 rgba(255, 0, 64, 0.7);
    }
    80% {
        box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
    }
}
@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&family=Manrope:wght@400;700;800&display=swap');

*{
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
html, body{
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: $black;
}
button, input{
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
li{
  list-style-type: none;
}
ul, li {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6, p{
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.weight {
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-600 {
    font-weight: 600;
  }
  &-800 {
    font-weight: 800;
  }
}

.color {
  &-black {
    color: $black;
  }
  &-white {
    color: $white;
  }
  &-dark-green {
    color: $dark-green;
  }
}


.title {
  &-1 {
    font-size: 20px;
    line-height: 24px;
  }
}
.text {
  &-1 {
    font-size: 16px;
    line-height: 19px;
  }
  &-2 {
    font-size: 14px;
    line-height: 17px;
  }
  &-3 {
    font-size: 10px;
    line-height: 12px;
  }
}

.react-international-phone-input-container
{
  width: 100%;
}
.react-international-phone-input{
  width: 100%;
}

.spin{
  animation:spin 1s linear infinite;
}

.fade-in-out {
  opacity: 0;
  animation: fadeInOut 2s ease-in-out infinite;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
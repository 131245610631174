@import "src/styles/variables";

.wrapper {
  padding: 42px 12px 28px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98px;
  height: 100%;
}

.menu {
  margin-bottom: 80px;
  cursor: pointer;
}

.exit {
  justify-self: flex-end;
  margin-top: auto;
  cursor: pointer;
  padding: 8px 25px;
  border-radius: 12px;
  border: 1px solid $white;
}

.sidebar {
  &-item {
    margin-bottom: 18px;
    padding: 8px 20px;
    background-color: transparent;
    border-radius: 12px;
    transition: 0.2s;
    cursor: pointer;
    &-active, &:hover {
      background-color: $white;
      svg path{
        fill: $green;
      }
    }
  }
}
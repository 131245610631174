$white: #FFFFFF;

$black: #383838;

$green: #51A39A;
$dark-green: #0C8275;
$light-green: #68B209;
$smooth-green: #CBEDA0;

$smooth-purple: #D8D1FF;

$light-orange: #E59D0F;
$smooth-orange: #F9DCA4;

$light-red: #BD0000;
$smooth-red: #FFD1D1;

$gray: #B3B3B3;
$dark-gray: #979797;
$light-gray: #F4F4F4;
$light-gray2: #E2E2E2;
$smooth-gray: #E7E7E7;
$smooth-gray2: #F0F2F2;


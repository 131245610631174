@import "src/styles/variables";

.wrapper {
  // background-color: $green;
  width: 100%;
  height: 100vh;
  display: flex;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
}